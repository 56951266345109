import { defineComponent, computed } from 'vue';
export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: () => '14px'
    },
    color: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const style = computed(() => {
      return {
        'font-size': props.size,
        color: props.color
      };
    });
    return {
      style
    };
  }
});