import { isExternal } from '@/utils/validate';
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: false
    },
    className: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#7F9AB8'
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.iconClass);
    },
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className;
      } else {
        return 'svg-icon';
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.iconClass}) no-repeat 50% 50%`
      };
    }
  }
};