import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-98233da2"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["element-loading-text"];
const _hoisted_2 = {
  style: {
    "display": "block"
  }
};
const _hoisted_3 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    "element-loading-text": _ctx.$t('message.loading'),
    "element-loading-background": "rgba(0, 0, 0, 0.5)",
    class: "childContainer WH"
  }, [_createElementVNode("div", {
    ref: "scrollContainer",
    vertical: false,
    style: _normalizeStyle($options.styleObj),
    class: "scroll-container"
  }, [_createElementVNode("template", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.childList, (item, index) => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      id: item.container.replace('#', ''),
      key: index,
      class: "sub-content-wrap WH"
    }, null, 8, _hoisted_3)), [[_vShow, $options.activation.startsWith(item.activeRule)]]);
  }), 128))])], 4)], 8, _hoisted_1)), [[_directive_loading, $options.loading, void 0, {
    fullscreen: true,
    lock: true
  }]]);
}