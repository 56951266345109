// 子容器
import app from "@/micros/app";
import { loadMicroApp, prefetchApps } from "qiankun";
export default {
  name: "ChildContainer",
  data() {
    return {
      microList: new Map()
    };
  },
  computed: {
    loading() {
      return this.$store.state.load.loading;
    },
    childList() {
      return app();
    },
    activation() {
      return this.$route.path || "";
    },
    childName({
      activation,
      childList
    }) {
      return childList.find(item => activation.startsWith(item.activeRule))?.name || "";
    },
    styleObj() {
      return {
        "padding-top": this.$store.state.common.menuPositionSet === "1" ? "0px" : "0px"
      };
    }
  },
  watch: {
    activation: {
      immediate: true,
      handler: "activationHandleChange"
    }
  },
  mounted() {
    prefetchApps([{
      name: 'courts',
      entry: window.g.VUE_APP_COURTS_URL
    }]);
  },
  beforeUnmount() {
    [...this.microList.values()].forEach(micro => {
      micro.unmount();
    });
  },
  methods: {
    //  监听路由变化，新增/修改/删除 缓存
    async activationHandleChange(path, oldPath) {
      this.$store.commit(`load/OPEN_LOADING`);
      await this.$nextTick();
      const {
        childList,
        microList
      } = this;
      const conf = childList.find(item => path.startsWith(item.activeRule));
      if (!conf) {
        setTimeout(() => {
          this.$store.commit(`load/CLOSE_LOADING`);
        }, 800);
        return;
      }

      // 如果已经加载过一次，则无需再次加载
      const current = microList.get(conf.activeRule);
      console.log("microList111", microList);
      if (current) {
        setTimeout(() => {
          this.$store.commit(`load/CLOSE_LOADING`);
        }, 800);
        return;
      }

      // 缓存当前子应用
      const micro = loadMicroApp({
        ...conf,
        router: this.$router
      }, {
        strictStyleIsolation: true,
        sandbox: {
          experimentalStyleIsolation: true
        },
        excludeAssetFilter: urls => {
          const whiteList = [];
          const whiteWords = ["baidu", "map", "api.map.baidu"]; // 异步加载百度地图白名单
          if (whiteList.includes(urls)) return true;
          return whiteWords.some(w => urls.includes(w));
        }
      });
      microList.set(conf.activeRule, micro);
      console.log("microList", microList);
      micro.mountPromise.finally(() => {
        //  9540 U-功能测试-网格管理-站房管理-添加拓扑-页面没有保存按钮  by yeyunli 2021/12/20
        if (!window.ht) {
          window.ht = window.proxy && window.proxy.ht;
          window.htconfig = window.proxy && window.proxy.htconfig;
          window.hteditor = window.proxy && window.proxy.hteditor;
        }
        setTimeout(() => {
          this.$store.commit(`load/CLOSE_LOADING`);
        }, 800);
      });
    }
  }
};